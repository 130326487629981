import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

import { PagesComponent } from './pages/pages.component';
import { APP_ROUTES } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { RouterModule } from '@angular/router';

import {MatSidenavModule} from '@angular/material/sidenav';
import { CalAngularModule } from '@cvx/cal-angular';
import { BalanceClient, EsfProfileClient, DictionariesClient, SntClient, TaxpayerStoreClient, UFormClient, UserClient, GroupRoleClient, AdminClient, GroupTaxpayerStoreClient, API_BASE_URL, AwpClient, NotificationClient } from './api/GCPClient';
import { CommonUpdateValuesService } from './services/commonUpdateValues.service';
import { TokenInterceptor } from './services/tokenInterceptor.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog'
import {ReactiveFormsModule} from '@angular/forms'
import {MatGridListModule} from '@angular/material/grid-list'
import {MatSelectModule} from '@angular/material/select'
import { FlexLayoutModule } from '@angular/flex-layout';
import '../app/Extensions/date.extensions'
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './materialModule.component';
import { EINVOICING_API_BASE_URL } from './api/EInvoicingApiClient';
import { GlobalErrorHandlingService } from './shared/services/global-error-handling.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlRus } from './shared/translations/mat-paginator-intl-rus';
import { environment } from 'src/environments/environment';

const APIServices = [
    TaxpayerStoreClient,
    BalanceClient,
    DictionariesClient,
    EsfProfileClient,
    UFormClient,
    EsfProfileClient,
    UFormClient,
    UserClient,
    GroupRoleClient,
    AdminClient,
    GroupTaxpayerStoreClient,
    SntClient,
    AwpClient
]

@NgModule({
    declarations: [
        AppComponent,
        PagesComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        LayoutModule,
        HttpClientModule,
        MatSidenavModule,
        CalAngularModule.forRoot(environment.cal),
        MatDialogModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatSelectModule,
        RouterModule.forRoot(APP_ROUTES, { enableTracing: false, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' }),
        SharedModule
    ],
    exports: [
        FlexLayoutModule,
        MaterialModule
    ],
    providers: [
        APIServices,
        { provide: EINVOICING_API_BASE_URL, useValue: environment.einvoicingApiUrl },
        CommonUpdateValuesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: API_BASE_URL,
            useValue: environment.cal["sntApiUrl"]
        },
        MatDatepickerModule,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlingService
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlRus },
        NotificationClient
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
